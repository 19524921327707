import { differenceInDays } from 'date-fns'

const useCalculateNights = (
  startDate?: unknown,
  endDate?: unknown
): number | null => {
  if (!startDate || !endDate) {
    return null
  }

  return differenceInDays(
    new Date(endDate as string),
    new Date(startDate as string)
  )
}

export default useCalculateNights
