//export const ROUTE_ERROR = '/error'
export const ROUTE_HOME = '/'

// export const ROUTE_SEARCH = '/search-reservation'
// export const ROUTE_YOUR_RESERVATION = `${ROUTE_SEARCH}/reservation`
// export const ROUTE_THANK_YOU = '/thank-you'
//
// export const ROUTE_FIND_ROOM = '/find-the-room'
//
// export const ROUTE_FIND_RESERVATION = '/find-reservation'
// export const ROUTE_RESERVE_ROOM = `${ROUTE_ROOMS_FOUND}/selection`
// export const ROUTE_CONFIRM_RESERVATION = '/find-reservation/confirm'
//
export const PATH_WORD_FIND_RESERVATION = 'find-reservation'
export const PATH_WORD_CONFIRM_EMAIL = 'confirm-email'
export const PATH_WORD_RESERVATION_DETAILS = 'reservation-details'
export const PATH_CARD_PRINTING = 'card-printing'
export const PATH_ROOMS_FOUND = 'rooms-found'

// make reservation flow
export const ROUTE_MAKE_RESERVATION = `/make-reservation`
export const ROUTE_MAKE_RESERVATION_ROOMS_FOUND = `${ROUTE_MAKE_RESERVATION}/${PATH_ROOMS_FOUND}`
export const ROUTE_MAKE_RESERVATION_DETAILS = `${ROUTE_MAKE_RESERVATION_ROOMS_FOUND}/${PATH_WORD_RESERVATION_DETAILS}`
export const ROUTE_MAKE_RESERVATION_DETAILS_INFO_KIOSK = `${ROUTE_MAKE_RESERVATION_ROOMS_FOUND}/${PATH_WORD_RESERVATION_DETAILS}/your-info`
export const ROUTE_MAKE_RESERVATION_PAYMENT = `${ROUTE_MAKE_RESERVATION_DETAILS}/payment`
export const ROUTE_MAKE_RESERVATION_PAYMENT_COMPLETED = `${ROUTE_MAKE_RESERVATION_DETAILS}/payment/completed`
export const ROUTE_MAKE_RESERVATION_CARD_PRINTING = `${ROUTE_MAKE_RESERVATION_PAYMENT_COMPLETED}/${PATH_CARD_PRINTING}`
export const ROUTE_MAKE_RESERVATION_THANK_YOU = `${ROUTE_MAKE_RESERVATION_CARD_PRINTING}/thank-you`

// check-in flow (kiosk only, no breadcrumbs)
export const ROUTE_CHECKIN_FIND_RESERVATION = `/checkin`
export const ROUTE_CHECKIN_CONFIRM_EMAIL = `/checkin/${PATH_WORD_FIND_RESERVATION}/${PATH_WORD_CONFIRM_EMAIL}`
export const ROUTE_CHECKIN_RESERVATION_DETAILS = `/checkin/${PATH_WORD_RESERVATION_DETAILS}`
export const ROUTE_CHECKIN_CARD_PRINTING = `/checkin/${PATH_CARD_PRINTING}`

// check-out flow
export const ROUTE_CHECKOUT_FIND_RESERVATION = `/checkout`
export const ROUTE_CHECKOUT_CONFIRM_EMAIL = `${ROUTE_CHECKOUT_FIND_RESERVATION}/${PATH_WORD_CONFIRM_EMAIL}`
export const ROUTE_CHECKOUT_RESERVATION_DETAILS = `${ROUTE_CHECKOUT_CONFIRM_EMAIL}/${PATH_WORD_RESERVATION_DETAILS}`
export const ROUTE_CHECKOUT_SUCCESS = `${ROUTE_CHECKOUT_CONFIRM_EMAIL}/${PATH_WORD_RESERVATION_DETAILS}/success`

// TODO: Breadcrums update
export const routeBreadCrumbNames = {
  // make reservation
  [ROUTE_HOME]: 'home',
  [ROUTE_MAKE_RESERVATION]: 'make reservation',
  [ROUTE_MAKE_RESERVATION_ROOMS_FOUND]: 'search results',
  [ROUTE_MAKE_RESERVATION_DETAILS]: 'your selection',
  [ROUTE_MAKE_RESERVATION_CARD_PRINTING]: 'final step',
  [ROUTE_MAKE_RESERVATION_THANK_YOU]: 'summary',
  [PATH_WORD_FIND_RESERVATION]: 'find room',
  [ROUTE_MAKE_RESERVATION_PAYMENT]: 'payment',
  [ROUTE_CHECKIN_FIND_RESERVATION]: 'search',
  [ROUTE_CHECKIN_CONFIRM_EMAIL]: 'confirm email',
  [ROUTE_CHECKIN_RESERVATION_DETAILS]: 'your reservation',
  [ROUTE_CHECKOUT_FIND_RESERVATION]: 'search',
  [ROUTE_CHECKOUT_CONFIRM_EMAIL]: 'confirm email',
  [ROUTE_CHECKOUT_RESERVATION_DETAILS]: 'your reservation',

  // [ROUTE_RESERVE_ROOM]: 'selection',
}
