// Common
import { fetchRooms } from './roomActions'

// Redux

// Types

const {
  fulfilled: { type: actionTypeFetchRoomsFulfilled },
} = fetchRooms

export const roomListeners = [
  // {
  //   matcher: isRejected(fetchMockData),
  //   effect: (
  //     action: { error: { message: string } }, // TODO: Action rejected type?
  //     { dispatch }: TAppListenerAPI
  //   ) => {
  //     console.log(action.error.message)
  //   },
  // },
  // {
  //   type: actionTypeFetchMockDataFulfilled,
  //   effect: (_: Action, { dispatch }: TAppListenerAPI) => {
  //     console.log('data succesfully fetched')
  //     // perform rest side effects here
  //   },
  // },
]
