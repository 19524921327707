import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import apiClient from 'api/client'
import { AxiosError } from 'axios'

interface User {
  uuid: string
  last_name: string
  first_name: string
  phone_number: string
  email: string | null
  updated_at: number
}

interface UserError {
  message: string
}

interface UserState {
  userDetails: User | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: UserState = {
  userDetails: null,
  status: 'idle',
  error: null,
}

export const fetchUser = createAsyncThunk<
  User,
  string,
  { rejectValue: UserError }
>('user/fetchUser', async (uuid, { rejectWithValue }) => {
  try {
    const response = await apiClient.get<{ data: User }>(`/users/${uuid}`)
    return response.data.data
  } catch (err) {
    const error: AxiosError<UserError> = err as AxiosError<UserError>
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUser.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.status = 'succeeded'
        state.userDetails = action.payload
      })
      .addCase(
        fetchUser.rejected,
        (state, action: PayloadAction<UserError | undefined>) => {
          state.status = 'failed'
          state.error = action.payload?.message ?? 'An error occurred'
        }
      )
  },
})

export default userSlice.reducer
