import { appSlice } from 'redux/slices/app/appSlice'

export * from 'redux/slices/app/appSelectors'

export const {
  setResolution,
  setDevice,
  setVariant,
  setKioskInputOpened,
  setReservationStep,
} = appSlice.actions

export default appSlice.reducer
