import { APP_VARIANT_ZEITRAUM, DEVICE_TYPE_KIOSK } from 'constants/app'
import { MQ_BREAKPOINT_LARGE } from 'constants/mediaQueries'
import { variantTheme } from 'theme'
import { IRootState } from 'types/redux'

export const selectVariantTheme = (state: IRootState) =>
  variantTheme[state.app.variant ?? APP_VARIANT_ZEITRAUM]

export const selectFitLargeResolution = (state: IRootState) =>
  (state.app.resolution ?? 0) >= MQ_BREAKPOINT_LARGE

export const selectFitCustomResolution = (state: IRootState, res: number) =>
  res > (state.app.resolution ?? 0)

export const selectIsKioskInputOpened = (state: IRootState) =>
  state.app.kiosk.inputOpened ?? false

export const selectIsKiosk = (state: IRootState) =>
  state.app.deviceType === DEVICE_TYPE_KIOSK

// TODO: Check if still useful (I moved logic to the router)
export const selectReservationStep = (state: IRootState) =>
  state.app.reservationStep
