import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import InputPhone from 'components/_inputs/InputPhone'
import InputSelect from 'components/_inputs/InputSelect'
import InputText from 'components/_inputs/InputText'
import { countryOptions } from 'constants/countries'
import { useAppSelector } from 'hooks/useRedux'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { selectFitCustomResolution, selectIsKiosk } from 'redux/slices/app'
import { IYourInfoForm } from 'types/room'
import { BorderFormBox } from 'views/desktopMobile/ViewYourReservation.styled'

const FormYourInfo = ({ form }: { form: UseFormReturn<IYourInfoForm> }) => {
  const { t } = useTranslation()
  const isKiosk = useAppSelector(selectIsKiosk)

  const isMobile = isKiosk
    ? false
    : useAppSelector(state => selectFitCustomResolution(state, 1118))

  return (
    <BorderFormBox
      mb={2}
      w="100%"
    >
      <Typography.Body1
        secondary
        mb={4}
      >
        {t('reservationDetails.desc')}
      </Typography.Body1>

      <Common.Div
        mb={2}
        flex={isMobile ? 'column' : 'row'}
      >
        <InputSelect
          label={t('reservationDetails.form.country')}
          name="country"
          form={form}
          options={countryOptions}
          mandatory
        />
        <InputText
          label={t('reservationDetails.form.firstName')}
          name="firstName"
          form={form}
          mt={isMobile ? 2 : 0}
          mr={isMobile ? 0 : 2}
          ml={isMobile ? 0 : 2}
          mb={isMobile ? 2 : 0}
          mandatory
        />
        <InputText
          label={t('reservationDetails.form.lastName')}
          name="lastName"
          form={form}
          mandatory
        />
      </Common.Div>

      <Common.Div
        mb={2}
        flex={isMobile ? 'column' : 'row'}
      >
        <Common.Div w="100%">
          <InputPhone
            label={t('reservationDetails.form.telephone')}
            name="phone"
            form={form}
            mandatory
          />
        </Common.Div>

        <InputText
          label={t('reservationDetails.form.email')}
          name="email"
          form={form}
          mt={isMobile ? 2 : 0}
          ml={isMobile ? 0 : 2}
          mb={isMobile ? 2 : 0}
          isMobile={isMobile}
          mandatory
        />
      </Common.Div>

      <Typography.Body2 secondary>
        {t('reservationDetails.form.validationRequired')}
      </Typography.Body2>
    </BorderFormBox>
  )
}

export default FormYourInfo
