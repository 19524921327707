import * as Common from 'components/_common/Common'
import { KioskViewContainer } from 'components/_common/Common'
import * as Typography from 'components/_common/TypographyKiosk'
import HomeMenuItems from 'components/_views/Home/HomeMenuItems'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { resetBookingStatus } from 'redux/slices/booking/bookingSlice'
import { resetReservationStatus } from 'redux/slices/reservation/reservationSlice'
import { resetRoomState } from 'redux/slices/room'
import { useTheme } from 'styled-components'

const ViewHomeKiosk = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const variantTheme = process.env.REACT_APP_VARIANT

  useEffect(() => {
    dispatch(resetBookingStatus())
    dispatch(resetReservationStatus())
    dispatch(resetRoomState())
  }, [dispatch])

  return (
    <KioskViewContainer bgUrl={theme.assets.landingBackground}>
      <Typography.H1
        mt={6}
        mb={2}
        align="center"
      >
        {t('welcome.header')}
      </Typography.H1>
      <Typography.Header
        mb={6}
        s={32}
        align="center"
      >
        {variantTheme === 'NO_BRAND'
          ? t('welcome.descNoBrand')
          : t('welcome.desc')}
      </Typography.Header>
      <Common.Div
        flex="column"
        gap="16px"
      >
        <HomeMenuItems />
      </Common.Div>
    </KioskViewContainer>
  )
}

export default ViewHomeKiosk
