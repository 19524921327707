import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import InputIncrementer from 'components/_inputs/InputIncrementer'
import { ROUTE_CHECKOUT_SUCCESS } from 'constants/routes'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { checkOut } from 'redux/slices/booking/bookingSlice'
import { selectReservationDetails } from 'redux/slices/reservation/reservationSelectors'
import { AppDispatch } from 'redux/store'

const AdditionalServices = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const bookingDetails = useSelector(selectReservationDetails)

  const form = useForm({
    defaultValues: {
      printout: 0,
      miniBar: 0,
      additionalRoomService: 0,
    },
  })

  const handleCheckout = async () => {
    if (bookingDetails?.uuid) {
      const resultAction = await dispatch(checkOut(bookingDetails.uuid))
      if (checkOut.fulfilled.match(resultAction)) {
        navigate(ROUTE_CHECKOUT_SUCCESS)
      } else if (checkOut.rejected.match(resultAction)) {
        // eslint-disable-next-line no-console
        console.error('Checkout failed:', Error)
      }
    } else {
      navigate(ROUTE_CHECKOUT_SUCCESS)
    }
  }

  return (
    <Common.Div
      mt={4}
      flex="column"
      alignItems="center"
    >
      <Typography.H3
        mb={2}
        secondary
      >
        {t('checkOut.details.header')}
      </Typography.H3>
      <Typography.Body2
        mb={6}
        secondary
      >
        {t('checkOut.details.desc')}
      </Typography.Body2>
      <Common.Div
        flex="column"
        gap="16px"
      >
        <Common.Div
          flex="row"
          gap="24px"
          alignItems="center"
        >
          <InputIncrementer
            name="printout"
            form={form}
          />

          <Typography.Body2 secondary>
            {t('checkOut.details.additionalPrintout')}
          </Typography.Body2>
        </Common.Div>

        <Common.Div
          flex="row"
          gap="24px"
          alignItems="center"
        >
          <InputIncrementer
            name="miniBar"
            form={form}
          />

          <Typography.Body2 secondary>
            {t('checkOut.details.miniBar')}
          </Typography.Body2>
        </Common.Div>

        <Common.Div
          flex="row"
          gap="24px"
          alignItems="center"
        >
          <InputIncrementer
            name="additionalRoomService"
            form={form}
          />

          <Typography.Body2 secondary>
            {t('checkOut.details.additionalRoomService')}
          </Typography.Body2>
        </Common.Div>
      </Common.Div>
      <Common.Div
        w="754px"
        mt={2}
      >
        <Common.Div
          flex="row"
          justifyContent="center"
          gap="16px"
          mt={2}
        >
          <Button
            onClick={handleCheckout}
            variant="secondary"
          >
            {t('checkOut.details.skipButton')}
          </Button>
          <Button onClick={handleCheckout}>
            {t('checkOut.details.addButton')}
          </Button>
        </Common.Div>
      </Common.Div>
    </Common.Div>
  )
}

export default AdditionalServices
