import BackButton from 'components/_common/BackButton'
import * as Common from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

const BackNextButtonsKiosk = ({
  handleBackButton,
  handleNextButton,
}: {
  handleBackButton: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleNextButton: (e: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  return (
    <Container>
      <Common.Div
        alignItems="center"
        bottom={45}
        flex="row"
        justifyContent="space-between"
        mt={2}
        position="fixed"
        px={6}
        py={1}
        w="100%"
      >
        <BackButton
          onClick={e =>
            handleBackButton(e as React.MouseEvent<HTMLButtonElement>)
          }
        />
        <NextButton
          onClick={e =>
            handleNextButton(e as React.MouseEvent<HTMLButtonElement>)
          }
        />
      </Common.Div>
    </Container>
  )
}

export default BackNextButtonsKiosk
