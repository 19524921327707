import * as Common from 'components/_common/Common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const StyledSelect = styled.select(
  ({ theme: { colors, spacing, params, isKiosk } }) => css`
    height: ${isKiosk ? '45px' : '50px'};
    padding: ${spacing(1)};
    border: none;
    color: ${colors.secondary};
    width: 100%;
    border-radius: ${params.buttonBorderRadius};
    background-color: transparent;
    :focus-visible {
      outline: none;
    }
  `
)

const LanguagePicker = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    void i18n.changeLanguage(event.target.value)
  }

  return (
    <Common.Div
      flex="row"
      alignItems="center"
    >
      <StyledSelect
        value={lang}
        onChange={handleLanguageChange}
        style={{ padding: '5px', borderRadius: '4px' }}
      >
        <option value="en">🇬🇧 EN</option>
        <option value="pl">🇵🇱 PL</option>
      </StyledSelect>
    </Common.Div>
  )
}

export default LanguagePicker
