import { createSlice } from '@reduxjs/toolkit'
import { initialState } from 'redux/slices/room/roomState'
import { reducers, extraReducers } from 'redux/slices/room/roomReducers'

export const roomSlice = createSlice({
  name: 'room',
  reducers,
  initialState,
  extraReducers,
})
