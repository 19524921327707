/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import ModalPaymentCompleted from 'components/_modals/ModalPaymentCompleted'
import BackNextButtonsKiosk from 'components/_views/ReservationDetailsSteps/BackNextButtonsKiosk'
import ReservationSteps from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RESERVATION_STEP_PAYMENT } from 'constants/app'
import {
  ROUTE_MAKE_RESERVATION_CARD_PRINTING,
  ROUTE_MAKE_RESERVATION_DETAILS_INFO_KIOSK,
} from 'constants/routes'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'redux/store'
import { ViewYourReservationContainer } from 'views/desktopMobile/ViewYourReservation.styled'

const ViewMakeReservationPaymentKiosk = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const bookingDetails = useSelector(
    (state: RootState) => state.booking.bookingDetails?.data ?? null
  )

  // TODO: Check if reservation form exists, otherwise redirect back

  return (
    <ViewYourReservationContainer isMobile={false}>
      <ReservationSteps step={RESERVATION_STEP_PAYMENT} />
      {/* <Typography.H4 mb={2}>{t('payment.title')}</Typography.H4> */}
      <ModalPaymentCompleted
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        bookingDetails={bookingDetails}
      />
      {/* <Common.Div
        flex="row"
        justifyContent="space-between"
        mt={2}
        w="100%"
      >
        <BackNextButtonsKiosk
          handleBackButton={() =>
            navigate(ROUTE_MAKE_RESERVATION_DETAILS_INFO_KIOSK)
          }
          handleNextButton={() =>
            navigate(ROUTE_MAKE_RESERVATION_CARD_PRINTING)
          }
        />
      </Common.Div> */}
    </ViewYourReservationContainer>
  )
}

export default ViewMakeReservationPaymentKiosk
