import iconGrayRight from 'assets/icons/iconGrayRight.svg'
import * as Common from 'components/_common/Common'
import { customProps } from 'components/_common/commonStyles'
import * as Typography from 'components/_common/Typography'
import { ROUTE_HOME, routeBreadCrumbNames } from 'constants/routes'
import { useAppSelector } from 'hooks/useRedux'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectFitCustomResolution } from 'redux/slices/app'
import styled, { css } from 'styled-components'

export const NavLink = styled(Typography.Body1)(
  () => css`
    ${customProps};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `
)

const Breadcrumbs = () => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 964)
  )

  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const segments = pathname.split('/').filter(Boolean)

  const locations = []
  locations.push(ROUTE_HOME)
  let currentRoute = ''

  for (let i = 0; i < segments.length; i++) {
    currentRoute += `/${segments[i]}`
    locations.push(currentRoute)
  }

  return (
    <Common.Div
      flex="column"
      mt={1}
    >
      <Common.Div
        flex="row"
        px={isMobile ? 3 : 6}
        py={1.5}
      >
        {locations.map((l, index) => (
          <Common.Div
            flex="row"
            key={l}
            alignItems="center"
            justifyContent="center"
          >
            {index !== segments.length ? (
              <NavLink
                s={16}
                onClick={() => navigate(l)}
              >
                {routeBreadCrumbNames[l as keyof typeof routeBreadCrumbNames]}
              </NavLink>
            ) : (
              <Typography.Body1
                secondary
                s={16}
              >
                {routeBreadCrumbNames[l as keyof typeof routeBreadCrumbNames]}
              </Typography.Body1>
            )}

            {index !== segments.length && (
              <Common.SvgIcon
                ml={1}
                mr={1}
                src={iconGrayRight}
              />
            )}
          </Common.Div>
        ))}
      </Common.Div>
      <Common.BarBorder mb={2} />
    </Common.Div>
  )
}

export default Breadcrumbs
