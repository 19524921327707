import * as Common from 'components/_common/Common'
import { KioskViewContainer } from 'components/_common/Common'
import * as Typography from 'components/_common/TypographyKiosk'
import HomeMenuItems from 'components/_views/Home/HomeMenuItems'
import { useAppSelector } from 'hooks/useRedux'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { selectFitCustomResolution } from 'redux/slices/app'
import { resetBookingStatus } from 'redux/slices/booking/bookingSlice'
import { resetReservationStatus } from 'redux/slices/reservation/reservationSlice'
import { resetRoomState } from 'redux/slices/room'
import { useTheme } from 'styled-components'

const ViewHome = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 800)
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetBookingStatus())
    dispatch(resetReservationStatus())
    dispatch(resetRoomState())
  }, [dispatch])

  return (
    <KioskViewContainer bgUrl={theme.assets.landingBackground}>
      <Typography.H1
        mt={isMobile ? 4 : 6}
        mb={2}
        align="center"
        s={isMobile ? 36 : 48}
      >
        {t('welcome.header')}
      </Typography.H1>
      <Typography.Header
        mb={6}
        s={isMobile ? 20 : 32}
        align="center"
      >
        {isMobile ? (
          <Trans i18nKey="welcome.descMobile" />
        ) : (
          <Trans i18nKey="welcome.desc" />
        )}
      </Typography.Header>
      <Common.Div
        flex="column"
        gap="16px"
        pl={isMobile ? 1 : 12}
        pr={isMobile ? 1 : 12}
        w="100%"
        alignItems="center"
      >
        <HomeMenuItems />
      </Common.Div>
    </KioskViewContainer>
  )
}

export default ViewHome
