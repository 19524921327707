import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'redux/slices/app/appState'
import { IAppState, TDeviceType, TReservationStepType, TVariant } from 'types'

export const reducers = {
  setVariant: (state: IAppState, action: PayloadAction<TVariant>) => {
    state.variant = action.payload
  },
  setResolution: (state: IAppState, action: PayloadAction<number>) => {
    state.resolution = action.payload
  },
  setDevice: (state: IAppState, action: PayloadAction<TDeviceType>) => {
    state.deviceType = action.payload
  },
  setKioskInputOpened: (state: IAppState, action: PayloadAction<boolean>) => {
    state.kiosk.inputOpened = action.payload
  },
  setReservationStep: (
    state: IAppState,
    action: PayloadAction<TReservationStepType>
  ) => {
    state.reservationStep = action.payload
  },
  resetAppState: () => initialState,
}

export const extraReducers = (
  _builder: ActionReducerMapBuilder<IAppState>
) => {}
