import {
  ROUTE_CHECKIN_CONFIRM_EMAIL,
  ROUTE_CHECKIN_FIND_RESERVATION,
  ROUTE_CHECKIN_RESERVATION_DETAILS,
  ROUTE_CHECKOUT_CONFIRM_EMAIL,
  ROUTE_CHECKOUT_FIND_RESERVATION,
  ROUTE_CHECKOUT_RESERVATION_DETAILS,
  ROUTE_CHECKOUT_SUCCESS,
  ROUTE_HOME,
  ROUTE_MAKE_RESERVATION,
  ROUTE_MAKE_RESERVATION_CARD_PRINTING,
  ROUTE_MAKE_RESERVATION_DETAILS,
  ROUTE_MAKE_RESERVATION_DETAILS_INFO_KIOSK,
  ROUTE_MAKE_RESERVATION_PAYMENT,
  ROUTE_MAKE_RESERVATION_PAYMENT_COMPLETED,
  ROUTE_MAKE_RESERVATION_ROOMS_FOUND,
  ROUTE_MAKE_RESERVATION_THANK_YOU,
} from 'constants/routes'
import LayoutBase from 'layouts/LayoutBase'
import ViewCheckInOutReservationNumber from 'views/desktopMobile/checkInOut/ViewCheckInOutReservationNumber'
import ViewCheckOutSuccess from 'views/desktopMobile/checkInOut/ViewCheckOutSuccess'
import ViewMakeReservationDetails from 'views/desktopMobile/makeReservation/ViewMakeReservationDetails'
import ViewMakeReservationPayment from 'views/desktopMobile/makeReservation/ViewMakeReservationPayment'
import ViewMakeReservationPaymentCompleted from 'views/desktopMobile/makeReservation/ViewMakeReservationPaymentCompleted'
import ViewMakeReservationPrintCard from 'views/desktopMobile/makeReservation/ViewMakeReservationPrintCard'
import ViewMakeReservationRoomsFound from 'views/desktopMobile/makeReservation/ViewMakeReservationRoomsFound'
import ViewMakeReservationSearchRooms from 'views/desktopMobile/makeReservation/ViewMakeReservationSearchRooms'
import ViewHome from 'views/desktopMobile/ViewHome'
import ViewThankYou from 'views/desktopMobile/ViewThankYou'
import ViewCheckInOutConfirmEmailKiosk from 'views/kiosk/checkInOut/ViewCheckInOutConfirmEmailKiosk'
import ViewCheckInOutReservationDetails from 'views/kiosk/checkInOut/ViewCheckInOutReservationDetails'
import ViewCheckOutSuccessKiosk from 'views/kiosk/checkInOut/ViewCheckOutSuccessKiosk'
import ViewMakeReservationDetailsInfoKiosk from 'views/kiosk/makeReservation/ViewMakeReservationDetailsInfoKiosk'
import ViewMakeReservationDetailsKiosk from 'views/kiosk/makeReservation/ViewMakeReservationDetailsKiosk'
import ViewMakeReservationPaymentKiosk from 'views/kiosk/makeReservation/ViewMakeReservationPaymentKiosk'
import ViewMakeReservationPrintCardKiosk from 'views/kiosk/makeReservation/ViewMakeReservationPrintCardKiosk'
import ViewMakeReservationRoomsFoundKiosk from 'views/kiosk/makeReservation/ViewMakeReservationRoomsFoundKiosk'
import ViewMakeReservationSearchRoomsKiosk from 'views/kiosk/makeReservation/ViewMakeReservationSearchRoomsKiosk'
import ViewMakeReservationThankYouKiosk from 'views/kiosk/makeReservation/ViewMakeReservationThankYouKiosk'
import ViewHomeKiosk from 'views/kiosk/ViewHomeKiosk'

const MAKE_RESERVATION_ROUTES = [
  {
    paths: [ROUTE_MAKE_RESERVATION],
    components: {
      desktop: ViewMakeReservationSearchRooms,
      kiosk: ViewMakeReservationSearchRoomsKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: false,
  },
  {
    paths: [ROUTE_MAKE_RESERVATION_ROOMS_FOUND],
    components: {
      desktop: ViewMakeReservationRoomsFound,
      kiosk: ViewMakeReservationRoomsFoundKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: true,
  },
  {
    paths: [ROUTE_MAKE_RESERVATION_DETAILS],
    components: {
      desktop: ViewMakeReservationDetails,
      kiosk: ViewMakeReservationDetailsKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: true,
  },
  {
    paths: [ROUTE_MAKE_RESERVATION_PAYMENT],
    components: {
      desktop: ViewMakeReservationPayment,
      kiosk: ViewMakeReservationPaymentKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: true,
  },
  {
    paths: [ROUTE_MAKE_RESERVATION_PAYMENT_COMPLETED],
    components: {
      desktop: ViewMakeReservationPaymentCompleted,
      kiosk: ViewMakeReservationPaymentKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: false,
  },
  {
    paths: [ROUTE_MAKE_RESERVATION_THANK_YOU],
    components: {
      desktop: ViewThankYou,
      kiosk: ViewMakeReservationThankYouKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: false,
  },
  {
    paths: [ROUTE_MAKE_RESERVATION_CARD_PRINTING],
    components: {
      desktop: ViewMakeReservationPrintCard,
      kiosk: ViewMakeReservationPrintCardKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: true,
  },
  {
    paths: [ROUTE_MAKE_RESERVATION_DETAILS_INFO_KIOSK],
    components: {
      desktop: ViewMakeReservationDetailsInfoKiosk,
      kiosk: ViewMakeReservationDetailsInfoKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: false,
  },
]

const CHECKIN_OUT_ROUTES = [
  {
    paths: [ROUTE_CHECKOUT_FIND_RESERVATION, ROUTE_CHECKIN_FIND_RESERVATION],
    components: {
      desktop: ViewCheckInOutReservationNumber,
      kiosk: ViewCheckInOutReservationNumber,
    },
    layout: LayoutBase,
    withBreadCrumbs: false,
  },
  {
    paths: [ROUTE_CHECKOUT_CONFIRM_EMAIL, ROUTE_CHECKIN_CONFIRM_EMAIL],
    components: {
      desktop: ViewCheckInOutConfirmEmailKiosk,
      kiosk: ViewCheckInOutConfirmEmailKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: false,
  },
  {
    paths: [
      ROUTE_CHECKOUT_RESERVATION_DETAILS,
      ROUTE_CHECKIN_RESERVATION_DETAILS,
    ],
    components: {
      desktop: ViewCheckInOutReservationDetails,
      kiosk: ViewCheckInOutReservationDetails,
    },
    layout: LayoutBase,
    withBreadCrumbs: true,
  },
  {
    paths: [ROUTE_CHECKOUT_SUCCESS],
    components: {
      desktop: ViewCheckOutSuccess,
      kiosk: ViewCheckOutSuccessKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: false,
  },
]

export const MAPPED_ROUTES = [
  {
    paths: [ROUTE_HOME],
    components: {
      desktop: ViewHome,
      kiosk: ViewHomeKiosk,
    },
    layout: LayoutBase,
    withBreadCrumbs: false,
  },
  ...MAKE_RESERVATION_ROUTES,
  ...CHECKIN_OUT_ROUTES,
]
