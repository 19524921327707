import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import { MandatoryStar } from 'components/_common/Typography'
import { customProps } from 'components/_common/commonStyles'
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

interface IInputSelectProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  disabled?: boolean
  form: UseFormReturn<T>
  options: { label: string; value: string }[]
  mandatory?: boolean
}

export const StyledSelect = styled.select<{ isError: boolean }>(
  ({ theme: { colors, spacing, params, isKiosk }, isError }) => css`
    ${customProps};
    height: ${isKiosk ? '45px' : '50px'};
    padding: ${spacing(1)};
    border: 1px solid ${isError ? colors.error : colors.textSecondary};
    color: ${colors.textSecondary};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border-radius: ${params.buttonBorderRadius};

    // TODO: Custom arrows
    //background-image: url("path/to/custom-arrow.png");
    //background-repeat: no-repeat;
    //background-position: right center;
    //background-size: 16px 16px;
    //padding-right: 24px; /* Add padding for the custom arrow */
  `
)

const InputSelect = <T extends FieldValues>({
  form,
  name,
  label = '',
  options = [],
  disabled = false,
  mandatory = false,
}: IInputSelectProps<T>) => {
  const { t } = useTranslation()
  const { control } = form

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value: formValue },
        formState: { errors },
      }) => {
        const error = errors[name] as { message: string }
        const value = formValue as string | string[]
        return (
          <Common.Div
            flex="column"
            w="100%"
          >
            {label && (
              <Common.Label secondary>
                {label}
                <MandatoryStar>{mandatory ? '*' : ''}</MandatoryStar>
              </Common.Label>
            )}
            <StyledSelect
              name={name}
              value={value}
              isError={!!error}
              disabled={disabled}
              onChange={onChange}
            >
              <option value="">{t('selectAnOption')}</option>
              {options.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </StyledSelect>
            {error && (
              <Typography.ErrorMessage>
                {error?.message ?? ''}
              </Typography.ErrorMessage>
            )}
          </Common.Div>
        )
      }}
    />
  )
}

export default InputSelect
