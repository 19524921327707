import * as Common from 'components/_common/Common'
import { KioskViewContainer, transparentCss } from 'components/_common/Common'
import * as Typography from 'components/_common/TypographyKiosk'
import FormSearchRoom from 'components/_forms/FormSearchRoom'
import { useTranslation } from 'react-i18next'
import styled, { css, useTheme } from 'styled-components'

export const FormContainer = styled(Common.Div)<{
  isInputOpened?: boolean
}>(
  ({ isInputOpened }) => css`
    padding: 38px 105px;
    min-height: 120px;
    ${transparentCss};
    /* ${isInputOpened && 'margin-top: 108px;'} */
  `
)

const ViewMakeReservationSearchRoomsKiosk = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  // const isInputOpened = useAppSelector(selectIsKioskInputOpened)

  return (
    <KioskViewContainer bgUrl={theme.assets.searchBackground}>
      {/* {!isInputOpened && ( */}
      <>
        <Typography.H1
          mt={6}
          mb={2}
          align="center"
          tertiary
        >
          {t('findTheRoom.header')}
        </Typography.H1>
        <Typography.Header
          mb={6}
          s={32}
          align="center"
          tertiary
        >
          {t('findTheRoom.desc')}
        </Typography.Header>
      </>
      {/* )} */}
      {/* <FormContainer isInputOpened={isInputOpened}> */}
      <FormContainer>
        <FormSearchRoom />
      </FormContainer>
    </KioskViewContainer>
  )
}

export default ViewMakeReservationSearchRoomsKiosk
