import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import {
  notify,
  ToastTypes,
} from 'components/_common/ToastNotification/ToastNotification'
import * as Typography from 'components/_common/Typography'
import AdditionalServices from 'components/_views/CheckInOut/AdditionalServices'
import BackNextButtonsKiosk from 'components/_views/ReservationDetailsSteps/BackNextButtonsKiosk'
import ReservationSteps from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RoomDetails } from 'components/_views/Room/RoomDetails'
import { RESERVATION_STEP_DETAILS } from 'constants/app'
import {
  ROUTE_CHECKIN_FIND_RESERVATION,
  ROUTE_CHECKIN_RESERVATION_DETAILS,
  ROUTE_CHECKOUT_FIND_RESERVATION,
  ROUTE_CHECKOUT_RESERVATION_DETAILS,
  ROUTE_MAKE_RESERVATION,
  ROUTE_MAKE_RESERVATION_CARD_PRINTING,
} from 'constants/routes'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectBooking } from 'redux/slices/booking/bookingSelectors'
import { checkIn } from 'redux/slices/booking/bookingSlice'
import { selectReservationDetails } from 'redux/slices/reservation/reservationSelectors'
import { AppDispatch } from 'redux/store'
import { ViewYourReservationContainer } from 'views/desktopMobile/ViewYourReservation.styled'

const ViewCheckInOutReservationDetails = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const isCheckout = pathname.includes('checkout')
  const [showAdditionalServices, setShowAdditionalServices] = useState(false)
  const bookingDetails = useSelector(selectReservationDetails)
  const bookingState = useSelector(selectBooking)

  const handleBackButton = () => {
    if (pathname === ROUTE_CHECKIN_RESERVATION_DETAILS) {
      navigate(ROUTE_CHECKIN_FIND_RESERVATION)
    } else if (pathname === ROUTE_CHECKOUT_RESERVATION_DETAILS) {
      navigate(ROUTE_CHECKOUT_FIND_RESERVATION)
    }
  }

  const handleNextButton = async () => {
    if (bookingDetails?.uuid) {
      const resultAction = await dispatch(checkIn(bookingDetails.uuid))
      if (checkIn.fulfilled.match(resultAction)) {
        navigate(ROUTE_MAKE_RESERVATION_CARD_PRINTING)
      }
    }
  }

  useEffect(() => {
    if (bookingState.status === 'failed') {
      notify(bookingState.error)[ToastTypes.ERROR]()
    }
  }, [dispatch, bookingState])

  return (
    <ViewYourReservationContainer isMobile>
      <Common.Div
        flex="column"
        alignItems="center"
      >
        <ReservationSteps
          step={RESERVATION_STEP_DETAILS}
          withStepBars={false}
        />

        {showAdditionalServices ? (
          <AdditionalServices />
        ) : (
          <>
            <Typography.H4
              mb={2}
              secondary
            >
              {t('reservationDetails.yourReservationDetails')}
            </Typography.H4>
            <RoomDetails>
              {isCheckout ? (
                <Common.Div
                  w="754px"
                  mt={2}
                >
                  <Common.Div
                    flex="row"
                    justifyContent="flex-end"
                    gap="16px"
                  >
                    <Button
                      onClick={() => navigate(ROUTE_MAKE_RESERVATION)}
                      variant="secondary"
                    >
                      {t('reservationDetails.buttonExtend')}
                    </Button>
                    <Button onClick={() => setShowAdditionalServices(true)}>
                      {t('reservationDetails.buttonCheckout')}
                    </Button>
                  </Common.Div>
                </Common.Div>
              ) : (
                <Common.Div
                  flex="row"
                  justifyContent="space-between"
                  mt={2}
                  w="100%"
                >
                  <BackNextButtonsKiosk
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                  />
                </Common.Div>
              )}
            </RoomDetails>
          </>
        )}
      </Common.Div>
    </ViewYourReservationContainer>
  )
}

export default ViewCheckInOutReservationDetails
