/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { StyledToastContainer } from './ToastNotification.styled'

export enum ToastTypes {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export const notify = (contentText: React.ReactNode | string) => {
  return {
    [ToastTypes.ERROR]: () => toast.error(contentText),
    [ToastTypes.SUCCESS]: () => toast.success(contentText),
    [ToastTypes.WARNING]: () => toast.warning(contentText),
  }
}

export const ToastNotification = () => {
  return (
    <StyledToastContainer
      autoClose={3000}
      closeButton={false}
      icon={false}
    />
  )
}
