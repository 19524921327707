import IconArrowLeft from 'assets/icons/iconArrowLeft.svg'
import IconArrowRight from 'assets/icons/iconArrowRight.svg'
import * as Common from 'components/_common/Common'
import { PageNumber } from 'views/desktopMobile/makeReservation/ViewMakeReservationRoomsFound.styled'

interface IPaginationProps {
  totalPages: number
  currentPageNumber: number
  setCurrentPageNumber: (arg: number) => void
}

const PAGES_LIMIT = 7

export const Pagination = ({
  totalPages,
  currentPageNumber,
  setCurrentPageNumber,
}: IPaginationProps) => {
  const calculatePageNumbers = () => {
    let startIndex = 1

    if (totalPages <= PAGES_LIMIT) {
      return Array.from(
        { length: totalPages },
        (_, index) => index + startIndex
      )
    }

    if (totalPages - currentPageNumber + 4 <= PAGES_LIMIT) {
      startIndex = totalPages - PAGES_LIMIT + 1
    } else if (currentPageNumber > 4) {
      startIndex = currentPageNumber - 3
    }

    return Array.from({ length: PAGES_LIMIT }, (_, index) => index + startIndex)
  }

  const handleArrowNext = () => {
    if (currentPageNumber !== totalPages) {
      setCurrentPageNumber(currentPageNumber + 1)
    }
  }

  const handleArrowPrev = () => {
    if (currentPageNumber !== 1) {
      setCurrentPageNumber(currentPageNumber - 1)
    }
  }

  return (
    <Common.Div flex="row">
      <Common.SvgIcon
        mr={1.5}
        src={IconArrowLeft}
        onClick={handleArrowPrev}
      />
      {calculatePageNumbers().map(pn => (
        <PageNumber
          key={`pegNumber-${pn}`}
          isActive={pn === currentPageNumber}
          onClick={() => setCurrentPageNumber(pn)}
        >
          {pn}
        </PageNumber>
      ))}
      <Common.SvgIcon
        ml={1.5}
        src={IconArrowRight}
        onClick={handleArrowNext}
      />
    </Common.Div>
  )
}
