import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import { MandatoryStar } from 'components/_common/Typography'
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styled, { css, useTheme } from 'styled-components'

interface IInputTextProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  disabled?: boolean
  form: UseFormReturn<T>
  type?: 'text' | 'number'
  mandatory?: boolean
}

const StyledPhoneInput = styled(PhoneInput)(() => css``)

const InputPhone = <T extends FieldValues>({
  name,
  form,
  label = '',
  disabled = false,
  mandatory = false,
  ...rest
}: IInputTextProps<T>) => {
  const { control } = form
  const theme = useTheme()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        const error = errors[name] as { message: string }

        return (
          <Common.Div
            flex="column"
            w="100%"
            {...rest}
          >
            {label && (
              <Common.Label secondary>
                {label}
                <MandatoryStar>{mandatory ? '*' : ''}</MandatoryStar>
              </Common.Label>
            )}
            <StyledPhoneInput
              value={value}
              disabled={disabled}
              onChange={onChange}
              country="pl"
              containerStyle={{
                height: theme.isKiosk ? '45px' : '50px',
                borderRadius: theme.params.buttonBorderRadius,
              }}
              inputStyle={{
                height: theme.isKiosk ? '45px' : '50px',
                borderRadius: theme.params.buttonBorderRadius,
                borderColor: '#00000080',
                width: '100%',
              }}
            />
            {error && (
              <Typography.ErrorMessage>
                {error?.message ?? ''}
              </Typography.ErrorMessage>
            )}
          </Common.Div>
        )
      }}
    />
  )
}

export default InputPhone
