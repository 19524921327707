import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import {
  fetchRooms,
  selectRooms,
  selectRoomSearchParams,
} from 'redux/slices/room'
import { IRoomsMeta } from 'types/room'

export const useRooms = (meta: IRoomsMeta) => {
  const dispatch = useAppDispatch()
  const searchData = useAppSelector(selectRoomSearchParams)
  const { rooms, isLoading, pagination } = useAppSelector(selectRooms)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const formattedMeta: IRoomsMeta = {
    adults: searchData?.adults,
    breakfast: meta?.breakfast ?? false,
    checkIn: searchData?.stayDate?.startDate,
    checkOut: searchData?.stayDate?.endDate,
    children: searchData?.children,
    page: meta?.page,
    parking: meta?.parking ?? false,
    perPage: meta?.perPage,
    roomsCount: searchData?.rooms,
    sort: sortOrder,
  }

  const handleSort = () => {
    setSortOrder(prevSortOrder => (prevSortOrder === 'desc' ? 'asc' : 'desc'))
  }

  useEffect(() => {
    void dispatch(fetchRooms(formattedMeta))
  }, [
    meta?.breakfast,
    meta?.language,
    meta?.page,
    meta?.parking,
    meta?.perPage,
    searchData?.stayDate,
    sortOrder,
  ])

  return {
    handleSort,
    sortOrder,
    isLoading,
    pagination,
    searchData,
    rooms,
  }
}
