import stepPrintCard from 'assets/graphics/stepCardPrinting.svg'
import stepGraphics from 'assets/graphics/stepReservationDetails.svg'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import {
  RESERVATION_STEP_DETAILS,
  RESERVATION_STEP_DETAILS_INFO,
  RESERVATION_STEP_PAYMENT,
  RESERVATION_STEP_PRINT_CARDS,
} from 'constants/app'
import { useAppSelector } from 'hooks/useRedux'
import { useTranslation } from 'react-i18next'
import { selectFitCustomResolution, selectIsKiosk } from 'redux/slices/app'
import styled, { css } from 'styled-components'
import { TReservationStepType } from 'types'

const mapStepGraphics = (step: TReservationStepType) => {
  switch (step) {
    case RESERVATION_STEP_DETAILS:
      return stepGraphics
    case RESERVATION_STEP_PAYMENT:
      return stepGraphics
    case RESERVATION_STEP_PRINT_CARDS:
      return stepPrintCard
    default:
      return stepGraphics
  }
}

export const StyledStepGraphics = styled(Common.SvgIcon)(
  () => css`
    width: 100%;
    max-width: 678px;
    max-height: 63px;
  `
)

export const StepTextWrapper = styled(Common.Div)(
  () => css`
    width: 100%;
    max-width: 678px;
  `
)

export const StepText = styled(Typography.Body2)<{ isActive?: boolean }>(
  ({ theme: { colors }, isActive }) => css`
    color: ${isActive ? colors.textSecondary : colors.border};
  `
)
export const StepBar = styled(Common.Div)<{ isActive?: boolean }>(
  ({ theme: { colors }, isActive }) => css`
    width: 54px;
    height: 5px;
    background: ${isActive ? colors.primary : colors.border};
  `
)

const ReservationSteps = ({
  step,
  withStepBars = true,
}: {
  step: TReservationStepType
  withStepBars?: boolean
}) => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 600)
  )
  const isKiosk = useAppSelector(selectIsKiosk)
  const { t } = useTranslation()

  return (
    <>
      <Common.Div
        flex="row"
        justifyContent="center"
        alignItems="center"
      >
        <StyledStepGraphics
          src={mapStepGraphics(step)}
          isMobile={isMobile}
        />
      </Common.Div>
      <StepTextWrapper
        flex="row"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        pr={3}
        pl={1}
      >
        <StepText
          align="center"
          isActive={
            step === RESERVATION_STEP_DETAILS ||
            step === RESERVATION_STEP_DETAILS_INFO ||
            step === RESERVATION_STEP_PRINT_CARDS
          }
        >
          {t('reservationDetails.findReservation')}
        </StepText>
        <StepText
          align="center"
          isActive={
            step === RESERVATION_STEP_DETAILS ||
            step === RESERVATION_STEP_DETAILS_INFO ||
            step === RESERVATION_STEP_PRINT_CARDS
          }
        >
          {t('reservationDetails.reservationDetails')}
        </StepText>
        <StepText
          isActive={step === RESERVATION_STEP_PRINT_CARDS}
          align="center"
        >
          {t('reservationDetails.cardPrinting')}
        </StepText>
      </StepTextWrapper>
      {withStepBars && (
        <Common.Div
          flex="row"
          gap="8px"
          justifyContent="center"
          mb={isKiosk ? 0 : 4}
        >
          <StepBar isActive={step === RESERVATION_STEP_DETAILS} />
          {isKiosk && (
            <StepBar isActive={step === RESERVATION_STEP_DETAILS_INFO} />
          )}
          <StepBar isActive={step === RESERVATION_STEP_PAYMENT} />
          <StepBar isActive={step === RESERVATION_STEP_PRINT_CARDS} />
        </Common.Div>
      )}
    </>
  )
}

export default ReservationSteps
