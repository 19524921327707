import checkoutSuccessBg from 'assets/bgs/checkoutBg.png'

import iconCheckHeart from 'assets/icons/iconCheckHeart.svg'
import iconCards from 'assets/icons/iconCards.svg'
import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import { ICustomStyledProps } from 'components/_common/commonStyles'

import * as Typography from 'components/_common/Typography'
import { ROUTE_HOME } from 'constants/routes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const ViewThankYouContainer = styled(Common.Div)<ICustomStyledProps>(
  () => css`
    background: url(${checkoutSuccessBg}) no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 170px);
  `
)

export const BorderWhite = styled(Common.Div)(
  () => css`
    width: 272px;
    height: 1px;
    border: 1px solid white;
  `
)

const ViewCheckOutSuccess = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ViewThankYouContainer
      fullyCentered
      p={4}
    >
      <Common.SvgIcon
        src={iconCheckHeart}
        mb={4}
        w="62px"
        h="58px"
      />
      <Typography.Body2
        tertiary
        mb={2}
      >
        {t('checkOut.success.subHeader')}
      </Typography.Body2>
      <Typography.H2
        tertiary
        mb={4}
        align="center"
      >
        {t('checkOut.success.header')}
      </Typography.H2>

      <BorderWhite mb={4} />

      <Common.SvgIcon
        src={iconCards}
        mb={4}
        w="62px"
        h="58px"
      />

      <Typography.Body2
        tertiary
        mb={4}
        align="center"
      >
        {t('checkOut.success.desc')}
      </Typography.Body2>

      <Button
        onClick={() => navigate(ROUTE_HOME)}
        variant="secondary"
      >
        {t('checkOut.success.okButton')}
      </Button>
    </ViewThankYouContainer>
  )
}

export default ViewCheckOutSuccess
