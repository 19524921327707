import { yupResolver } from '@hookform/resolvers/yup'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import FormYourInfo from 'components/_forms/FormYourInfo'
import BackNextButtonsKiosk from 'components/_views/ReservationDetailsSteps/BackNextButtonsKiosk'
import ReservationSteps from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RoomDetails } from 'components/_views/Room/RoomDetails'
import { RESERVATION_STEP_DETAILS } from 'constants/app'
import {
  ROUTE_MAKE_RESERVATION_PAYMENT,
  ROUTE_MAKE_RESERVATION_ROOMS_FOUND,
} from 'constants/routes'
import { useAppSelector } from 'hooks/useRedux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectFitCustomResolution } from 'redux/slices/app'
import { IYourInfoForm } from 'types/room'
import { errorMessagesSchema } from 'utils/validation/reservation'
import { ViewYourReservationContainer } from 'views/desktopMobile/ViewYourReservation.styled'

const ViewMakeReservationDetails = () => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 600)
  )
  const { t } = useTranslation()
  const { reservationSchema } = errorMessagesSchema(t)
  const navigate = useNavigate()
  const formReservation = useForm<IYourInfoForm>({
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      country: '',
    },
    resolver: yupResolver(reservationSchema),
  })

  const handleBackButton = () => {
    navigate(ROUTE_MAKE_RESERVATION_ROOMS_FOUND)
  }

  const handleNextButton = () => {
    void formReservation.handleSubmit(
      () => {
        navigate(ROUTE_MAKE_RESERVATION_PAYMENT)
      },
      // eslint-disable-next-line no-console
      e => console.log(e)
    )()
  }

  return (
    <ViewYourReservationContainer isMobile={isMobile}>
      <ReservationSteps step={RESERVATION_STEP_DETAILS} />
      <>
        <Typography.H4
          mb={2}
          secondary
        >
          {t('reservationDetails.roomDetailsHeader')}
        </Typography.H4>
        <RoomDetails />
        <Typography.H4
          mt={4}
          mb={2}
        >
          {t('reservationDetails.roomDetailsInfo')}
        </Typography.H4>
        <FormYourInfo form={formReservation} />
        <Common.Div
          flex="row"
          justifyContent="space-between"
          mt={2}
          w="100%"
          mb={8}
        >
          <BackNextButtonsKiosk
            handleBackButton={handleBackButton}
            handleNextButton={handleNextButton}
          />
        </Common.Div>
      </>
    </ViewYourReservationContainer>
  )
}

export default ViewMakeReservationDetails
