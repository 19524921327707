import { IRootState } from 'types/redux'
import { IReservationDetails } from 'types/room'

export const selectReservationState = (state: IRootState) => state.reservation

export const selectReservationDetails = (
  state: IRootState
): IReservationDetails | null => state.reservation.reservationDetails

export const selectReservationStatus = (state: IRootState): string =>
  state.reservation.fetchStatus

export const selectVerifyStatus = (state: IRootState): string =>
  state.reservation.verifyStatus

export const selectVerifyMessage = (state: IRootState): string | null =>
  state.reservation.verifyMessage

export const selectReservationError = (state: IRootState): string | null =>
  state.reservation.error
