import cardPrintedBg from 'assets/bgs/cardsPrintingBg.png'
import creditCardIcon from 'assets/icons/iconCreditCard.svg'
import fvIconSmall from 'assets/icons/iconFvSmall.svg'
import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import WideModal from 'components/_modals/WideModal'
import { ROUTE_MAKE_RESERVATION_THANK_YOU } from 'constants/routes'
import { t } from 'i18next'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BookingResponse } from 'redux/slices/booking/bookingSlice'
import { selectUserDetails } from 'redux/slices/user/userSelector'
import { fetchUser } from 'redux/slices/user/userSlice'
import { ICommonModalProps } from 'types/common'

type ModalActionsProps = {
  setInvoicePrintingModalIsOpen: (arg: boolean) => void
  setIsOpen: (arg: boolean) => void
  email: string | null
}

const ModalActions = ({
  setInvoicePrintingModalIsOpen,
  setIsOpen,
  email,
}: ModalActionsProps) => {
  const navigate = useNavigate()

  return (
    <Common.Div
      flex="row"
      justifyContent="space-between"
      alignItems="center"
      h="120px"
    >
      <Typography.Body4
        s={14}
        secondary
      >
        <Trans i18nKey="cardPrinting.modal.actionText" />
        <strong>{email} </strong>
        <Trans i18nKey="cardPrinting.modal.actionTextCd" />
      </Typography.Body4>
      <Common.Div
        flex="row"
        gap="16px"
      >
        <Button
          variant="secondary"
          onClick={() => navigate(ROUTE_MAKE_RESERVATION_THANK_YOU)}
        >
          {t('cardPrinting.modal.backButton')}
        </Button>
        <Button
          type="button"
          onClick={() => {
            setInvoicePrintingModalIsOpen(true)
            setIsOpen(false)
          }}
        >
          <Common.SvgIcon
            src={fvIconSmall}
            mr={2}
          />
          {t('cardPrinting.modal.printButton')}
        </Button>
      </Common.Div>
    </Common.Div>
  )
}

type ModalCardsPrintingProps = ICommonModalProps & {
  setInvoicePrintingModalIsOpen: (arg: boolean) => void
  bookingDetails: BookingResponse['data'] | null
}

const ModalCardsPrinting = ({
  isOpen,
  setIsOpen,
  setInvoicePrintingModalIsOpen,
  bookingDetails,
}: ModalCardsPrintingProps) => {
  const dispatch = useDispatch()
  const userDetails = useSelector(selectUserDetails)

  useEffect(() => {
    if (bookingDetails?.users_uuid) {
      dispatch(fetchUser(bookingDetails.users_uuid))
    }
  }, [bookingDetails?.users_uuid, dispatch])

  if (!bookingDetails) {
    return null
  }

  return (
    <WideModal
      variant="action"
      bgSrc={cardPrintedBg}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      bottomChildren={
        <ModalActions
          email={userDetails?.email ?? null}
          setInvoicePrintingModalIsOpen={setInvoicePrintingModalIsOpen}
          setIsOpen={setIsOpen}
        />
      }
      closeOnClickOutside={false}
    >
      <Common.Div
        flex="column"
        fullyCentered
      >
        <Common.SvgIcon
          src={creditCardIcon}
          mb={2}
        />
        <Typography.H2
          tertiary
          mb={2}
        >
          {t('cardPrinting.modal.header')}
        </Typography.H2>
        <Typography.Body2 tertiary>
          {t('cardPrinting.modal.desc')}
        </Typography.Body2>
      </Common.Div>
    </WideModal>
  )
}

export default ModalCardsPrinting
