export const DEVICE_TYPE_COMPUTER = 'computer'
export const DEVICE_TYPE_KIOSK = 'kiosk'

export const APP_VARIANT_NO_BRAND = 'NO_BRAND'
export const APP_VARIANT_ZEITRAUM = 'ZEITRAUM'

export const RESERVATION_STEP_DETAILS = 'details'
export const RESERVATION_STEP_DETAILS_INFO = 'info' // additional step on kiosk only
export const RESERVATION_STEP_PAYMENT = 'payment'
export const RESERVATION_STEP_PRINT_CARDS = 'printCards'
