import { IRootState } from 'types/redux'

export const selectRooms = (state: IRootState) => ({
  rooms: state.room.rooms.data ?? [],
  isLoading: state.room.rooms.isLoading ?? false,
  pagination: state.room.rooms?.meta?.pagination ?? {},
})

export const selectRoomSearchParams = (state: IRootState) =>
  state.room.searchParams

export const selectReservationDetails = (state: IRootState) =>
  state.room.reservationData

export const selectSelectedRoom = (state: IRootState) => state.room.selectedRoom
