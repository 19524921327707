import paymentCompletedBg from 'assets/bgs/paymentCompletedBg.png'
import creditCardCheck from 'assets/icons/iconCreditCardCheck.svg'
import qrCodeIcon from 'assets/icons/iconQr.svg'
import * as Common from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import * as Typography from 'components/_common/Typography'
import ModalPaymentCompleted from 'components/_modals/ModalPaymentCompleted'
import { ROUTE_MAKE_RESERVATION_CARD_PRINTING } from 'constants/routes'
import { useAppSelector } from 'hooks/useRedux'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectFitCustomResolution } from 'redux/slices/app'
import { RootState } from 'redux/store'
import styled, { css } from 'styled-components'

const Background = styled(Common.Div)(
  () => css`
    width: 100%;
    height: 440px;
    background: url(${paymentCompletedBg}) no-repeat;
  `
)

const ViewMakeReservationPayment = () => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 960)
  )
  const isSm = useAppSelector(state => selectFitCustomResolution(state, 700))
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const { t } = useTranslation()
  const bookingDetails = useSelector(
    (state: RootState) => state.booking.bookingDetails?.data ?? null
  )

  // TODO: Check if reservation form exists, otherwise redirect back

  return (
    <>
      {isMobile ? (
        <Common.Div>
          <Background>
            <Common.Div
              flex="column"
              fullyCentered
            >
              <Common.SvgIcon
                src={creditCardCheck}
                mb={2}
              />
              <Typography.H2
                tertiary
                mb={2}
              >
                {t('payment.modal.header')}
              </Typography.H2>
              <Typography.Body2 tertiary>
                {t('payment.modal.desc')}
              </Typography.Body2>
            </Common.Div>
          </Background>
          <Common.Div
            flex={isSm ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            h="130px"
            p={2}
            gap="16px"
          >
            <Common.Div
              flex="row"
              gap="32px"
            >
              <Common.SvgIcon
                src={qrCodeIcon}
                ml={4}
              />
              <Typography.Body4
                s={14}
                secondary
              >
                <Trans i18nKey="payment.modal.descMobile" />
              </Typography.Body4>
            </Common.Div>
            <NextButton
              onClick={() => navigate(ROUTE_MAKE_RESERVATION_CARD_PRINTING)}
            />
          </Common.Div>
        </Common.Div>
      ) : (
        <ModalPaymentCompleted
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          bookingDetails={bookingDetails}
        />
      )}
    </>
  )
}

export default ViewMakeReservationPayment
