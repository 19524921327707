import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import i18n from 'i18next'
import translationEn from 'locales/en_translation.json'
import translationPl from 'locales/pl_translation.json'
import { ReactNode, useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { initReactI18next } from 'react-i18next/initReactI18next'
import { Provider } from 'react-redux'
import {
  selectIsKiosk,
  selectVariantTheme,
  setDevice,
  setResolution,
  setVariant,
} from 'redux/slices/app'
import store from 'redux/store'
import RouterApp from 'routers/RouterApp'
import { ThemeProvider } from 'styled-components'
import { TDeviceType, TVariant } from 'types'
import GlobalStyles from './globalStyles'

const resources = {
  en: {
    translation: translationEn,
  },
  pl: {
    translation: translationPl,
  },
}

const ThemedApp = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch()
  const device = process.env.REACT_APP_DEVICE as TDeviceType
  const isKiosk = useAppSelector(selectIsKiosk)

  useEffect(() => {
    dispatch(setDevice(device))
    dispatch(setVariant(process.env.REACT_APP_VARIANT as TVariant))

    if (isKiosk) {
      const handleResize = () => {
        const { innerWidth } = window
        dispatch(setResolution(innerWidth))
      }
      handleResize()

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [dispatch, device, isKiosk])

  useEffect(() => {
    const link = document.querySelector("link[rel='icon']") as HTMLLinkElement

    if (process.env.REACT_APP_VARIANT === 'NO_BRAND') {
      link.href = '/favicon-no-brand.ico'
    } else {
      link.href = '/favicon.ico'
    }
  }, [])

  const variantTheme = useAppSelector(selectVariantTheme)
  variantTheme.isKiosk = isKiosk

  return (
    <ThemeProvider theme={variantTheme}>
      <GlobalStyles isKiosk={isKiosk} />
      {children}
    </ThemeProvider>
  )
}

const App = () => {
  void i18n.init({
    interpolation: { escapeValue: false },
  })

  void i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

  return (
    <Provider store={store}>
      <ThemedApp>
        <I18nextProvider i18n={i18n}>
          <RouterApp />
        </I18nextProvider>
      </ThemedApp>
    </Provider>
  )
}

export default App
