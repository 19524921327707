import IconArrowDown from 'assets/icons/iconArrowDown.svg'
import IconArrowUp from 'assets/icons/iconArrowUp.svg'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import { useAppSelector } from 'hooks/useRedux'
import { useTranslation } from 'react-i18next'
import { selectIsKiosk } from 'redux/slices/app'

export const SortBy = ({
  handleSort,
  sortOrder,
}: {
  handleSort: () => void
  sortOrder: string
}) => {
  const { t } = useTranslation()
  const isKiosk = useAppSelector(selectIsKiosk)

  return (
    <Common.Div
      flex="row"
      justifyContent="flex-end"
      w="100%"
      pt={1}
      pr={isKiosk ? 5 : 0}
    >
      <Common.Div
        flex="row"
        gap="8px"
        onClick={handleSort}
      >
        <Typography.Body4
          bold
          secondary
        >
          {t('roomsFound.sortBy')}
        </Typography.Body4>
        <Typography.Body4 secondary>
          {`${t('roomsFound.sortByPrice')} - ${
            sortOrder === 'asc'
              ? t('roomsFound.sortLowest')
              : t('roomsFound.sortHigher')
          } `}
        </Typography.Body4>

        <Common.SvgIcon
          src={sortOrder === 'asc' ? IconArrowUp : IconArrowDown}
        />
      </Common.Div>
    </Common.Div>
  )
}
