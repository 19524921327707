import { IRoomState } from 'types/room'

export const initialState: IRoomState = {
  rooms: {
    data: [],
    meta: {
      pagination: {
        count: 0,
        current_page: 0,
        links: {
          next: '',
        },
        per_page: 0,
        total: 0,
        total_pages: 0,
      },
    },
    isLoading: false,
  },
  selectedRoom: null,
  searchParams: {
    adults: 1,
    children: 0,
    rooms: 1,
    stayDate: {
      startDate: '',
      endDate: '',
      nights: 0,
    },
    breakfast: false,
    parking: false,
  },
}
