import IconHelp from 'assets/icons/iconHelp.svg'
import Breadcrumbs from 'components/_common/Breadcrumbs'
import * as Common from 'components/_common/Common'
import LanguagePicker from 'components/_common/LanguagePicker'
import * as Typography from 'components/_common/Typography'
import { ROUTE_HOME } from 'constants/routes'
import { useAppSelector } from 'hooks/useRedux'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectFitCustomResolution, selectIsKiosk } from 'redux/slices/app'
import styled, { css, useTheme } from 'styled-components'

interface ILayoutBaseProps {
  children: ReactNode
  withBreadcrumbs?: boolean
}

const Bar = styled(Common.Div)(
  ({ theme: { colors } }) => css`
    width: 100%;
    height: 80px;
    background: ${colors.bar};
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 99;
  `
)

const TopBar = styled(Bar)(
  () => css`
    top: 0;
  `
)
const BottomBar = styled(Bar)(
  ({ theme: { isKiosk } }) => css`
    bottom: 0;
    height: 90px;
    align-items: center;

    ${isKiosk &&
    css`
      height: 45px;
    `}
  `
)

const ResponsiveContainer = styled.div(
  ({ theme: { isKiosk } }) => css`
    width: 100%;
    min-height: calc(100vh - 170px);
    margin-top: 80px;
    overflow: auto;
    height: 100vh;

    ${isKiosk &&
    css`
      height: calc(100vh - 80px);
    `}
  `
)

const LayoutBase = ({
  children,
  withBreadcrumbs = false,
}: ILayoutBaseProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 964)
  )
  const isResponsiveFooter = useAppSelector(state =>
    selectFitCustomResolution(state, 530)
  )

  const isKiosk = useAppSelector(selectIsKiosk)

  const navigate = useNavigate()

  return (
    <>
      <TopBar
        px={isMobile ? 3 : 6}
        justifyContent="space-between"
      >
        <Common.SvgIcon
          src={theme.assets.companyLogo ?? ''}
          onClick={() => navigate(ROUTE_HOME)}
          w="min(40%, 150px)"
        />
        <Common.Div
          flex="row"
          gap="64px"
        >
          <LanguagePicker />
          {theme.assets.hotelLogo && (
            <Common.SvgIcon
              src={theme.assets.hotelLogo}
              h="35px"
            />
          )}
        </Common.Div>
      </TopBar>

      <ResponsiveContainer>
        {withBreadcrumbs && !isKiosk && <Breadcrumbs />}
        {children}
      </ResponsiveContainer>

      <BottomBar
        px={isMobile ? 3 : 6}
        flex={isResponsiveFooter ? 'column' : 'row'}
        justifyContent={isResponsiveFooter ? 'center' : 'space-between'}
        flexDirection={isResponsiveFooter ? 'column-reverse' : 'row'}
      >
        <Typography.Body2
          tertiary
          align="left"
        >
          {t('navigation.privacyPolicy')}
        </Typography.Body2>
        <Common.Div
          flex="row"
          alignItems="center"
          mb={isResponsiveFooter ? 1 : 0}
        >
          <Common.SvgIcon src={IconHelp} />
          <Typography.Body2
            tertiary
            ml={1}
          >
            {t('navigation.help')}
          </Typography.Body2>
        </Common.Div>
      </BottomBar>
    </>
  )
}

export default LayoutBase
