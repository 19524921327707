import iconExtend from 'assets/icons/iconCalendar.svg'
import iconCheckout from 'assets/icons/iconCheckout.svg'
import iconFindReservation from 'assets/icons/iconFindReservation.svg'
import iconFindRoom from 'assets/icons/iconFindRoom.svg'
import iconArrowRight from 'assets/icons/iconWhiteArrowRight.svg'
import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/TypographyKiosk'
import {
  ROUTE_CHECKIN_FIND_RESERVATION,
  ROUTE_CHECKOUT_FIND_RESERVATION,
  ROUTE_MAKE_RESERVATION,
} from 'constants/routes'
import { useAppSelector } from 'hooks/useRedux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectFitCustomResolution, selectIsKiosk } from 'redux/slices/app'
import styled, { css } from 'styled-components'

const MenuItem = styled(Common.Div)(
  ({ theme: { colors, isKiosk } }) => css`
    width: 844px;
    height: 80px;
    background: ${colors.secondary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 20px;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
    gap: 32px;

    ${!isKiosk &&
    css`
      width: 100%;
      max-width: 1080px;

      @media (max-width: 800px) {
        height: 124px;
        padding: 16px;
      }
    `}
  `
)

const StartButtonDesktop = styled(Button)(
  () => css`
    @media (max-width: 800px) {
      width: 50px;
      height: 50px;
    }
  `
)

// TODO: Update extend your stay route
const welcomeButtons = [
  {
    icon: iconFindRoom,
    titleKey: 'welcome.makeAReservationHeader',
    subtitleKey: 'welcome.checkinHeader',
    route: ROUTE_MAKE_RESERVATION,
  },
  {
    icon: iconFindReservation,
    titleKey: 'welcome.checkinHeader',
    subtitleKey: 'welcome.checkinDesc',
    route: ROUTE_CHECKIN_FIND_RESERVATION,
    kioskOnly: true,
  },
  {
    icon: iconCheckout,
    titleKey: 'welcome.checkOutHeader',
    subtitleKey: 'welcome.checkOutDesc',
    route: ROUTE_CHECKOUT_FIND_RESERVATION,
  },
  // {
  //   icon: iconExtend,
  //   titleKey: 'welcome.extendHeader',
  //   subtitleKey: 'welcome.extendDesc',
  //   route: ROUTE_MAKE_RESERVATION,
  // },
]

const HomeMenuItems = () => {
  const navigate = useNavigate()
  const isKiosk = useAppSelector(selectIsKiosk)
  const variant = process.env.REACT_APP_VARIANT
  const { t } = useTranslation()
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 800)
  )

  const buttons = isKiosk
    ? welcomeButtons
    : welcomeButtons.filter(i => !i.kioskOnly)

  return (
    <>
      {buttons.map(({ icon, titleKey, subtitleKey, route }) => (
        <MenuItem key={titleKey}>
          <Common.Div flex="row">
            <Common.SvgIcon
              src={icon}
              mr={3}
            />
            <Common.Div flex="column">
              <Typography.Header
                mb={2}
                s={20}
                secondary={variant === 'NO_BRAND'}
              >
                {t(titleKey)}
              </Typography.Header>
              <Typography.Body
                s={14}
                secondary
                regular
              >
                {t(subtitleKey)}
              </Typography.Body>
            </Common.Div>
          </Common.Div>
          {isKiosk ? (
            <Button onClick={() => navigate(route)}>START</Button>
          ) : (
            <StartButtonDesktop onClick={() => navigate(route)}>
              {isMobile ? <Common.SvgIcon src={iconArrowRight} /> : 'START'}
            </StartButtonDesktop>
          )}
        </MenuItem>
      ))}
    </>
  )
}

export default HomeMenuItems
