import ModalThankYou from 'components/_modals/ModalThankYou'
import { useState } from 'react'

const ViewMakeReservationThankYouKiosk = () => {
  const [isModalOpen, setIsModalOpen] = useState(true)

  return (
    <ModalThankYou
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
    />
  )
}

export default ViewMakeReservationThankYouKiosk
