import hotelMockCards from 'assets/graphics/hotelMockCards.png'
import * as Common from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import * as Typography from 'components/_common/Typography'
import ReservationSteps from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RESERVATION_STEP_PRINT_CARDS } from 'constants/app'
import { ROUTE_MAKE_RESERVATION_THANK_YOU } from 'constants/routes'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ViewMakeReservationPrintCard = () => {
  const navigate = useNavigate()

  return (
    <Common.Div
      flex="column"
      alignItems="center"
      w="100%"
      pl={4}
      pr={4}
      pt={8}
    >
      <ReservationSteps step={RESERVATION_STEP_PRINT_CARDS} />
      <Typography.H4
        mb={2}
        s={26}
      >
        <Trans i18nKey="cardPrinting.rememberToPrint" />
      </Typography.H4>
      <Typography.Body2
        secondary
        mb={4}
        s={18}
        align="center"
      >
        <Trans i18nKey="cardPrinting.toMoveAroundHotel" />
      </Typography.Body2>
      <Common.SvgIcon src={hotelMockCards} />
      <Common.Div
        flex="row"
        justifyContent="flex-end"
        mt={2}
        pr={8}
        w="100%"
      >
        <NextButton
          onClick={() => navigate(ROUTE_MAKE_RESERVATION_THANK_YOU)}
        />
      </Common.Div>
    </Common.Div>
  )
}

export default ViewMakeReservationPrintCard
