import { ITypographyProps, spacingProps } from 'components/_common/commonStyles'
import styled, { css, DefaultTheme } from 'styled-components'

export const commonTypographyStyles = (
  p: ITypographyProps & { theme: DefaultTheme }
) =>
  css`
    text-align: ${p.align ?? 'inherit'};
    margin: 0;
    padding: 0;
    ${p.onClick && 'cursor: pointer'};
    font-weight: ${p.bold ? 500 : 400};
    font-family: ${p.regular
      ? p.theme.fontFamily.regular
      : p.theme.fontFamily.medium};

    ${p.s && `font-size: ${p.s}px;`};
    color: ${() => {
      if (p.secondary) return p.theme.colors.textSecondary
      if (p.tertiary) return p.theme.colors.textTertiary
      return p.theme.colors.text
    }};
  `

export const H1 = styled.h1<ITypographyProps>(
  () => css`
    font-size: 48px;
    ${commonTypographyStyles};
    ${spacingProps};
  `
)

export const Header = styled.h2<ITypographyProps>(
  () => css`
    ${commonTypographyStyles};
    ${spacingProps};
  `
)

export const Body = styled.p<ITypographyProps>(
  () => css`
    ${commonTypographyStyles};
    ${spacingProps};
  `
)
