import { RESERVATION_STEP_DETAILS } from 'constants/app'
import { IAppState, TDeviceType, TVariant } from 'types'

export const initialState: IAppState = {
  variant: process.env.REACT_APP_VARIANT as TVariant,
  resolution: null,
  deviceType: process.env.REACT_APP_DEVICE as TDeviceType,
  kiosk: {
    inputOpened: false,
  },
  // TODO: Check if still needed
  reservationStep: RESERVATION_STEP_DETAILS,
}
