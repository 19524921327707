import checkoutSuccessBg from 'assets/bgs/checkoutBg.png'
import * as Common from 'components/_common/Common'
import { ICustomStyledProps } from 'components/_common/commonStyles'
import ModalCheckOutSuccess from 'components/_modals/ModalCheckOutSuccess'
import { useState } from 'react'
import styled, { css } from 'styled-components'

export const ViewThankYouContainer = styled(Common.Div)<ICustomStyledProps>(
  () => css`
    background: url(${checkoutSuccessBg}) no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 170px);
  `
)

export const BorderWhite = styled(Common.Div)(
  () => css`
    width: 272px;
    height: 1px;
    border: 1px solid white;
  `
)

const ViewCheckOutSuccessKiosk = () => {
  const [isModalOpen, setIsModalOpen] = useState(true)

  return (
    <ModalCheckOutSuccess
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
    />
  )
}

export default ViewCheckOutSuccessKiosk
