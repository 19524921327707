// global.js
import { normalize } from 'styled-normalize'

import { createGlobalStyle, css } from 'styled-components'

export default createGlobalStyle<{ isKiosk: boolean }>`
  ${normalize}
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html,
  #root {
    scroll-behavior: smooth;
  }
  
  
  
  ${({ isKiosk }) => css`
    body {
      background: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.textSecondary};
      font-family: ${({ theme }) => theme.mainFont};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      // Disable scroll on kiosk version
      ${isKiosk && 'overflow-y: hidden;'}
    }

    text {
      color: ${({ theme }) => theme.colors.text};
    }

    iframe {
      display: none;
    }

    // NOTE: for react-date-picker
    .rdrStartEdge,
    .rdrDay,
    .rdrEndEdge,
    .rdrInRange {
      color: ${({ theme }) => theme.colors.primary} !important;
    }

    // NOTE: maybe use another lib because of styling
    .rs-picker-toggle {
      margin: 0;
      border-radius: 0;
      border: 1px solid #00000080;
      display: flex;
      align-items: center;
      height: 50px;
      width: 366px;
      padding: 0 16px;
    }
  `}

 

  `
