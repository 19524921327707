import { enUS, pl } from 'date-fns/locale'

const localeMap: { [key: string]: Locale } = {
  en: enUS,
  pl,
}

export const getDateFnsLocale = (language: string): Locale =>
  localeMap[language] || enUS

export const countDaysBetweenDates = (
  startDate: string | Date,
  endDate: string | Date
) => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  const difference = Math.abs(end.getTime() - start.getTime())

  return Math.ceil(difference / (1000 * 60 * 60 * 24))
}

export const formatDate = (date: Date | string): string => {
  if (typeof date === 'string') return date
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
