import cardChip from 'assets/graphics/cardChip.png'
import HotelLogo from 'assets/graphics/hotel_logo.png'
import * as Common from 'components/_common/Common'
import { ICustomStyledProps } from 'components/_common/commonStyles'
import * as Typography from 'components/_common/Typography'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

export const RoomCardContainer = styled(Common.Card)<ICustomStyledProps>(
  ({ theme: { colors, spacing } }) => css`
    padding: ${spacing(3)};
    font-size: 12px;
    background: ${colors.cardFront};
    font-weight: 400;
    width: 165px;
    height: 262px;
    text-align: left;
    border-radius: 16px;

    p {
      opacity: 0.5;
      color: ${colors.secondary};
    }
  `
)

export const BackCardContainer = styled(Common.Card)<ICustomStyledProps>(
  ({ theme: { spacing } }) => css`
    padding: ${spacing(3)};
    text-align: left;
    width: 165px;
    height: 262px;
    border-radius: 16px;
  `
)

export const RoomNumber = styled.p<ICustomStyledProps>(
  () => css`
    font-size: 62px;
    text-align: right;
    font-weight: 500;
    margin: 0;
  `
)

export const RoomNumberLegend = styled.span<ICustomStyledProps>(
  ({ theme: { colors } }) => css`
    color: ${colors.secondary};
    text-align: right;
    font-size: 8px;
  `
)

export const RoomCard = () => {
  const { t } = useTranslation()

  return (
    <RoomCardContainer mr={4}>
      <Common.SvgIcon
        src={HotelLogo}
        mb={4}
      />
      <Typography.Body2 s={13}>{t('welcome.header')}!</Typography.Body2>
      <Typography.Body2
        mb={2}
        s={13}
      >
        {t('reservationDetails.welcomeMessage')}
      </Typography.Body2>
      <RoomNumber>305</RoomNumber>
      <RoomNumberLegend>{t('reservationDetails.roomNumber')}</RoomNumberLegend>
    </RoomCardContainer>
  )
}

export const BackCard = () => {
  const { t } = useTranslation()

  return (
    <BackCardContainer>
      <Typography.Body2
        mb={6}
        s={10}
        secondary
      >
        {t('reservationDetails.departureDayMessage')}
      </Typography.Body2>
      <Typography.Body2
        mb={2}
        s={10}
        secondary
      >
        {t('reservationDetails.wifiPassword')} guest/hotellogo
      </Typography.Body2>
      <Common.SvgIcon
        mb={2}
        w="22px"
        h="24px"
        src={cardChip}
      />
      <Typography.Body2
        s={10}
        secondary
      >
        www.hotellogo.com
      </Typography.Body2>
    </BackCardContainer>
  )
}
