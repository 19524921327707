// Common
import { createListenerMiddleware } from '@reduxjs/toolkit'

// Listeners
import { roomListeners } from 'redux/slices/room/roomListeners'

// Utils

// Types
import { TAppStartListening } from 'types/redux'

export const listenerMiddleware = createListenerMiddleware({
  // extra: { getCommonData },
})

export const startAppListening =
  listenerMiddleware.startListening as TAppStartListening

const listeners = [...roomListeners]

listeners.forEach(listener => {
  startAppListening(listener as never) // TODO: figure out type
})
