import * as yup from 'yup'
import { TFunction } from 'i18next'

export const confirmReservationSchema = (t: TFunction) =>
  yup.object().shape({
    confirmationNumber: yup
      .string()
      .typeError(t('validation.invalidConfirmationNumber'))
      .required(t('validation.required')),
  })

export const searchSchema = (t: TFunction) =>
  yup.object().shape({
    resNumber: yup
      .string()
      .typeError(t('validation.invalidReservationNumber'))
      .required(t('validation.required')),
  })

export const findRoomSchema = (t: TFunction) =>
  yup.object().shape({
    adults: yup
      .number()
      .required(t('searchForm.required'))
      .min(1, t('searchForm.minAdults')),
    rooms: yup
      .number()
      .required(t('searchForm.required'))
      .min(1, t('searchForm.minRooms')),
    stayDate: yup.object().shape({
      startDate: yup.string().required(t('searchForm.requiredStartDate')),
      endDate: yup.string().required(t('searchForm.requiredEndDate')),
    }),
  })
