import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getRooms } from 'api/room/roomsApi'
import { IRequestResponse } from 'types/api'
import { IRoom, IRoomsMeta } from 'types/room'

export const fetchRooms = createAsyncThunk<IRequestResponse<IRoom>, IRoomsMeta>(
  'app/getRooms',
  async (meta, _) => {
    return getRooms(meta)
  }
)

export const selectRoom = createAction<IRoom>('room/selectRoom')
