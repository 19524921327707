import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { listenerMiddleware } from 'redux/middleware/listeners'
import app from 'redux/slices/app'
import room from 'redux/slices/room'
import bookingSlice from './slices/booking/bookingSlice'
import reservationReducer from './slices/reservation/reservationSlice'
import user from './slices/user/userSlice'

export const appReducer = combineReducers({
  app,
  room,
  booking: bookingSlice,
  user,
  reservation: reservationReducer,
  // router: connectRouter(history),
})

// Configure store
const store = configureStore({
  reducer: appReducer,
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'prod',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      // thunk: { extraArgument: { getCommonData } },
    })
      // NOTE: Since this can receive actions with functions inside,
      // it should go before the serializability check middleware
      .prepend(listenerMiddleware.middleware),
})

// Named exports to avoid circular dependency in middleware
export const { dispatch, getState } = store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
