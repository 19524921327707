import axios from 'axios'
import i18n from 'i18next'

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL as string}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

API.interceptors.request.use(config => {
  config.headers['X-Language'] = i18n.language || 'en'
  return config
})

export default API
