import snowflakeIcon from 'assets/icons/iconSnowflake.svg'
import wifiIcon from 'assets/icons/iconWifi.svg'

import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import { useAppSelector } from 'hooks/useRedux'
import { selectIsKiosk } from 'redux/slices/app'
import styled, { css } from 'styled-components'

export const FacilityBox = styled(Common.Div)(
  ({ theme: { colors, spacing } }) => css`
    border: 1px ${colors.lightBorder} solid;
    padding: ${spacing(1)};
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: ${spacing(2)};
    }
  `
)

export const FacilityIcon = styled(Common.SvgIcon)(
  ({ theme: { isKiosk } }) => css`
    width: 24px;
    height: 24px;

    /* ${isKiosk &&
    css`
      width: 20px;
      height: 20px;
    `} */
  `
)

interface IFacilitiesList {
  list: string[]
  iconsOnly?: boolean
}

const mapFacilityToIcon = (f: string) => {
  switch (f) {
    case 'wifi':
      return {
        icon: wifiIcon,
        label: 'Free WiFi',
      }
    case 'air':
      return {
        icon: snowflakeIcon,
        label: 'Air conditioning',
      }
    default:
      return {
        icon: snowflakeIcon,
        label: 'Air conditioning',
      }
  }
}

const FacilitiesList = ({ list, iconsOnly = false }: IFacilitiesList) => {
  const isKiosk = useAppSelector(selectIsKiosk)

  return (
    <Common.Div flex="row">
      {list.map(listItem => {
        const { icon, label } = mapFacilityToIcon(listItem)
        return iconsOnly || isKiosk ? (
          <FacilityIcon
            src={icon}
            mr={1}
            key={label}
          />
        ) : (
          <FacilityBox key={label}>
            <FacilityIcon
              src={icon}
              mr={1}
            />
            <Typography.Body2 secondary>{label}</Typography.Body2>
          </FacilityBox>
        )
      })}
    </Common.Div>
  )
}

export default FacilitiesList
