import NoBrandCompanyLogo from 'assets/variants/noBrand/companyLogo.svg'
import NoBrandLandingBackground from 'assets/variants/noBrand/searchBackground.png'
import ZeitraumCompanyLogo from 'assets/variants/zeitraum/companyLogo.svg'
import ZeitraumHoteLogo from 'assets/variants/zeitraum/hotelLogo.svg'
import ZeitraumLandingBackground from 'assets/variants/zeitraum/landingBackground.png'
import ZeitraumSearchBackground from 'assets/variants/zeitraum/searchBackground.png'
import { APP_VARIANT_NO_BRAND, APP_VARIANT_ZEITRAUM } from 'constants/app'

export const SPACING_PX = 8

export const commonTheme = {
  spacing: (n: number) => `${n * SPACING_PX}px`,
  fontFamily: {
    regular: 'DinProRegular',
    medium: 'DinProMedium',
  },
  isKiosk: false,
}

const COLORS_PALETTE = {
  black: '#000000',
  white: '#FFFFFF',
  yellow: 'yellow',
  darkJeans: '#2a4686',
  navyBlue: 'rgba(34, 58, 113, 1)',
  crimsonRed: '#cd2121',
  grayIntrovert: '#9b9b9b',
  graySomething: '#E3E3E3',
  grayTransparent: '#00000080',
  blackyGray: '#30303096',
  greyishBrown: '#545454',
  lightGreyBorder: '#CBCBCB',
  oldGolden: 'rgba(164, 134, 30, 1)',
  gradientBlue: 'linear-gradient(180deg, #2A4686 0%, #3661C4 100%)',
  gradientBluish: 'linear-gradient(106.89deg, #2A4686 11.43%, #000819 161.41%)',
}

const commonColors = {
  secondary: COLORS_PALETTE.white,
  modalBackground: COLORS_PALETTE.white,
  modalOverlay: COLORS_PALETTE.blackyGray,
  textTertiary: COLORS_PALETTE.white,
  background: COLORS_PALETTE.white,
  gradient: 'linear-gradient(#39598A, #79D7ED)',
  buttonBorder: '#FFF',
  border: COLORS_PALETTE.lightGreyBorder,
  lightBorder: COLORS_PALETTE.greyishBrown,
  tableHeadBackground: COLORS_PALETTE.graySomething,
  cardFront: COLORS_PALETTE.gradientBlue,
  error: COLORS_PALETTE.crimsonRed,
}

export const zeitraumTheme = {
  ...commonTheme,
  mainFont: 'DinProMedium',
  colors: {
    ...commonColors,
    primary: COLORS_PALETTE.darkJeans,
    bar: COLORS_PALETTE.darkJeans,
    text: COLORS_PALETTE.darkJeans,
    textSecondary: COLORS_PALETTE.greyishBrown,
    textHeader: COLORS_PALETTE.darkJeans,
    // cardText: COLORS_PALETTE.darkJeans,
  },
  assets: {
    companyLogo: ZeitraumCompanyLogo,
    hotelLogo: ZeitraumHoteLogo,
    landingBackground: ZeitraumLandingBackground,
    searchBackground: ZeitraumSearchBackground,
  },
  params: {
    buttonBorderRadius: '0',
  },
  texts: {
    reservationSystem: 'Zeitraum Reservation system',
  },
}

const noBrandTheme = {
  ...commonTheme,
  mainFont: 'EncodeSans',
  colors: {
    ...commonColors,
    primary: COLORS_PALETTE.oldGolden,
    bar: COLORS_PALETTE.gradientBluish,
    text: COLORS_PALETTE.white,
    textSecondary: COLORS_PALETTE.navyBlue,
    textHeader: COLORS_PALETTE.white,
    // cardText: COLORS_PALETTE.navyBlue,
  },
  assets: {
    companyLogo: NoBrandCompanyLogo,
    hotelLogo: '',
    landingBackground: NoBrandLandingBackground,
    searchBackground: NoBrandLandingBackground,
  },
  params: {
    buttonBorderRadius: '0px',
  },
  texts: {
    reservationSystem: 'our reservation system',
  },
}

export const variantTheme = {
  [APP_VARIANT_NO_BRAND]: noBrandTheme,
  [APP_VARIANT_ZEITRAUM]: zeitraumTheme,
}

export type TVariantTheme = typeof noBrandTheme
