import IconArrowWhiteRight from 'assets/icons/iconWhiteArrowRight.svg'
import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import { ICustomStyledProps } from 'components/_common/commonStyles'
import { useTranslation } from 'react-i18next'

interface INextButtonProps extends ICustomStyledProps {
  disabled?: boolean
  type?: 'submit'
}

const NextButton = ({ disabled, onClick, type }: INextButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {t('navigation.next')}
      <Common.SvgIcon
        ml={1.5}
        src={IconArrowWhiteRight}
      />
    </Button>
  )
}

export default NextButton
