import API from 'api/client'
import { IRequestResponse } from 'types/api'
import { IRoom, IRoomsMeta } from 'types/room'
import { formatDate } from 'utils/dates'

export const getRooms = async (meta: IRoomsMeta) => {
  const params = [
    `adults=${meta.adults ?? 1}`,
    `breakfast=${meta.breakfast ? '1' : '0'}`,
    `check_in=${meta.checkIn ? formatDate(meta.checkIn) : ''}`,
    `check_out=${meta.checkOut ? formatDate(meta.checkOut) : ''}`,
    `children=${meta.children ?? 0}`,
    `parking=${meta.parking ? '1' : '0'}`,
    `rooms_count=${meta.roomsCount ?? 1}`,
    `sort[price]=${meta.sort ?? 'asc'}`,
    meta.page ? `page=${meta.page}` : '',
    meta.perPage ? `per_page=${meta.perPage}` : '',
  ]
    .filter(Boolean)
    .join('&')

  const query = `room?${params}`

  const { data }: { data: IRequestResponse<IRoom> } = await API.get(query)

  return data
}
